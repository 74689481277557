






















import { Action } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        PagePresetGuest: () => import('@/layouts/presets/Guest.vue'),
        FormBase: () => import('@/components/forms/Base.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        ContactToggle: () => import('@/components/contact/ContactToggle.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
    },
})
export default class PageForgotPassword extends Vue {
    @Action('user/forgotPassword') forgotPassword!: (payload: ForgotPasswordPayload) => Promise<Auth>;

    i18n: string = 'views.forgot_password';

    success: boolean = false;

    $refs!: Vue['$refs'] & {
        forgotPasswordForm: FormClass;
    };

    payload: ForgotPasswordPayload = {
        email: undefined,
    };

    private forgotPasswordSubmit() {
        const $form: FormClass = this.$refs.forgotPasswordForm;
        this.forgotPassword(this.payload)
            .then(() => {
                $form.handleSuccess();
                this.success = true;
            })
            .catch((e: ErrorResponse) => $form.handleErrors(e));
    }
}
